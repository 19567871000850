import {useParams} from "react-router-dom";
import {useGetAllProjectsQuery} from "../store/api/projectApi";
import {ICategory, IProject, IType} from "../models/models";
import ProjectSkills from "../components/ProjectSkills";
import React, {FC} from "react";
import AppliedFreelancers from "../components/AppliedFreelancers";
import {useTranslation} from "react-i18next";
import {useGetAllCategoryQuery} from "../store/api/categoryApi";
import ApplyRemoveBtns from "../components/ApplyRemoveBtns";
import {useAuth} from "../hooks/useAuth";
import MatchedFreelancerToProject from "../components/MatchedFreelancerToProject";
import PageTitle from "../components/PageTitle";
import {useGetAllTypesQuery} from "../store/api/typeApi";
import LikeBtn from "../components/LikeBtn";
import CreateChatBtn from "../components/CreateChatBtn";


interface ProjectPageProps {
    mode?: string
}

const ProjectPage: FC<ProjectPageProps> = ({mode}) => {

    const user = useAuth()

    const {t} = useTranslation()

    const {projectId} = useParams<string>();

    const {projectsData} = useGetAllProjectsQuery(undefined, {
        selectFromResult: ({data: projectsData}) => ({
            projectsData: projectsData?.filter((item: IProject) => item.id === Number(projectId))
        }),
    });

    const {data: types} = useGetAllTypesQuery(undefined, {
        selectFromResult: ({data: types}) => ({
            data: types?.filter((item: IType) => {
                return projectsData?.some((project: IProject) => project?.type_id === item.id);
            }),
        }),
    });

    const {data: categories} = useGetAllCategoryQuery(undefined, {
        selectFromResult: ({data: categories}) => ({
            data: categories?.filter((item: ICategory) => {
                return projectsData?.some((project: IProject) => project.category_id === item.id);
            }),
        }),
    });


    // console.log(data)
    // console.log(categories)

    return (
        <>
            <PageTitle title={projectsData?.[0].title ? projectsData?.[0].title : ""}/>
            <div className="jp_listing_single_main_wrapper">
                <div className="container">
                    {projectsData?.[0] &&
                        <>
                            <div className="row">
                                <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                                    <div className="jp_listing_left_sidebar_wrapper">
                                        <div className="jp_job_des">
                                            <h2>{t("ProjectPage.job_description")}</h2>
                                            <p>{projectsData?.[0].description}</p>
                                        </div>
                                        <div className="jp_job_res">
                                            <h2>{t("ProjectPage.infos")}</h2>
                                            <p>{projectsData?.[0].infos}</p>
                                        </div>
                                        <div className="jp_job_map">
                                            <h2>{t("ProjectPage.location")}</h2>
                                            <p>{projectsData?.[0].location}</p>
                                        </div>
                                    </div>
                                    <ProjectSkills project={projectsData?.[0]}/>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <div
                                                className="jp_rightside_job_categories_wrapper jp_rightside_listing_single_wrapper">
                                                <div className="jp_rightside_job_categories_heading">
                                                    <h4>{t("ProjectPage.job_overview")}</h4>
                                                </div>
                                                <div className="jp_job_listing_single_post_right_cont">
                                                    <div className="jp_job_listing_single_post_right_cont_wrapper">
                                                        <h4>{projectsData?.[0].title}</h4>
                                                        <p>{projectsData?.[0].contact}</p>
                                                        <ul>
                                                            <li><i
                                                                className="fa fa-cc-paypal"></i>&nbsp; {projectsData?.[0].hourly_rate}
                                                            </li>
                                                            <li><i
                                                                className="fa fa-map-marker"></i>&nbsp; {projectsData?.[0].location}
                                                            </li>
                                                            {user && user?.id && user?.type !== "admin" && projectsData?.[0] &&
                                                                <li className="pt-3">
                                                                    <LikeBtn user={user} project={projectsData?.[0]}/>
                                                                </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/*<div className="jp_job_post_right_overview_btn_wrapper">*/}
                                                {/*    <div className="jp_job_post_right_overview_btn">*/}
                                                {/*        <ul>*/}
                                                {/*            */}

                                                {/*        </ul>*/}
                                                {/*    </div>*/}
                                                {/*</div>*/}
                                                <div className="jp_listing_overview_list_outside_main_wrapper">
                                                    <div className="jp_listing_overview_list_main_wrapper">
                                                        <div className="jp_listing_list_icon">
                                                            <i className="fa fa-calendar"></i>
                                                        </div>
                                                        <div className="jp_listing_list_icon_cont_wrapper">
                                                            <ul>
                                                                <li>{t("ProjectPage.date_posted")}:</li>
                                                                <li>{projectsData?.[0].date_posted}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="jp_listing_overview_list_main_wrapper jp_listing_overview_list_main_wrapper2">
                                                        <div className="jp_listing_list_icon">
                                                            <i className="fa fa-map-marker"></i>
                                                        </div>
                                                        <div className="jp_listing_list_icon_cont_wrapper">
                                                            <ul>
                                                                <li>{t("ProjectPage.location")}:</li>
                                                                <li>{projectsData?.[0].location}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="jp_listing_overview_list_main_wrapper jp_listing_overview_list_main_wrapper2">
                                                        <div className="jp_listing_list_icon">
                                                            <i className="fa fa-building-o"></i>
                                                        </div>
                                                        <div className="jp_listing_list_icon_cont_wrapper">
                                                            <ul>
                                                                <li>Type:</li>
                                                                {types?.[0]?.type &&
                                                                    <li>{types?.[0]?.type}</li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="jp_listing_overview_list_main_wrapper jp_listing_overview_list_main_wrapper2">
                                                        <div className="jp_listing_list_icon">
                                                            <i className="fa fa-info-circle"></i>
                                                        </div>
                                                        <div className="jp_listing_list_icon_cont_wrapper">
                                                            <ul>
                                                                <li>{t("ProjectPage.job_title")}:</li>
                                                                <li>{projectsData?.[0].title}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="jp_listing_overview_list_main_wrapper jp_listing_overview_list_main_wrapper2">
                                                        <div className="jp_listing_list_icon">
                                                            <i className="fa fa-clock-o"></i>
                                                        </div>
                                                        <div className="jp_listing_list_icon_cont_wrapper">
                                                            <ul>
                                                                <li>{t("ProjectPage.remote")}:</li>
                                                                <li>{projectsData?.[0].remote}%</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="jp_listing_overview_list_main_wrapper jp_listing_overview_list_main_wrapper2">
                                                        <div className="jp_listing_list_icon">
                                                            <i className="fa fa-money"></i>
                                                        </div>
                                                        <div className="jp_listing_list_icon_cont_wrapper">
                                                            <ul>
                                                                <li>{t("ProjectPage.hourly_rate")}:</li>
                                                                <li>{projectsData?.[0].hourly_rate}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    {categories?.[0] &&
                                                        <div
                                                            className="jp_listing_overview_list_main_wrapper jp_listing_overview_list_main_wrapper2">
                                                            <div className="jp_listing_list_icon">
                                                                <i className="fa fa-th-large"></i>
                                                            </div>
                                                            <div className="jp_listing_list_icon_cont_wrapper">
                                                                <ul>
                                                                    <li>{t("ProjectPage.category")}:</li>
                                                                    <li>{categories?.[0].category}</li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }
                                                    {/*<div*/}
                                                    {/*    className="jp_listing_overview_list_main_wrapper jp_listing_overview_list_main_wrapper2">*/}
                                                    {/*    <div className="jp_listing_list_icon">*/}
                                                    {/*        <i className="fa fa-star"></i>*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className="jp_listing_list_icon_cont_wrapper">*/}
                                                    {/*        <ul>*/}
                                                    {/*            <li>Experience:</li>*/}
                                                    {/*            <li>1+ Years Experience</li>*/}
                                                    {/*            <li>DO WE NEED THIS</li>*/}
                                                    {/*        </ul>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    {user && user?.type !== "admin" && !user.error &&
                                                        <div className="jp_listing_right_bar_btn_wrapper">
                                                            <div className="jp_listing_right_bar_btn">
                                                                <ul>
                                                                    <li>
                                                                        <ApplyRemoveBtns project={projectsData?.[0]}
                                                                                         user={user}/>
                                                                    </li>
                                                                    <li className="mt-5">
                                                                        <CreateChatBtn project={projectsData?.[0]}
                                                                                       invited_u_id={34}/>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {mode && mode === "admin" &&
                                <>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <AppliedFreelancers viewType="block" project={projectsData?.[0]}/>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                            <MatchedFreelancerToProject project={projectsData?.[0]}/>
                                        </div>
                                    </div>
                                </>

                            }
                        </>
                    }
                </div>
            </div>
        </>
    )
}
export default ProjectPage