import {ICategory, IFreelancer} from "../models/models";
import {FC, useState} from "react";
import {useTranslation} from "react-i18next";


interface JobByCategoryProps {
    chooseCategory: (categoryId: number) => void;
    categoriesData: ICategory[] | undefined
    user?: IFreelancer | undefined
}

const JobByCategory: FC<JobByCategoryProps> = ({categoriesData, chooseCategory, user}) => {

    const {t} = useTranslation()

    const [activeCategoryId, setActiveCategoryId] = useState<number | null>(-1);

    const handleClick = (categoryId: number) => {
        setActiveCategoryId(categoryId);
        chooseCategory(categoryId);
    };

    return (
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="jp_rightside_job_categories_wrapper">
                <div className="jp_rightside_job_categories_heading">
                    <h4>{t("JobByCategory.jobs_by_category")}</h4>
                </div>
                <div className="jp_rightside_job_categories_content">
                    <ul>
                        {user && user?.type === "freelancer" &&
                            <li
                                role="button"
                                onClick={() => handleClick(0)}
                                className={activeCategoryId === 0 ? "active_category" : ""}
                            >
                                <i className="fa fa-caret-right"></i>{" "}
                                <span>{t("JobByCategory.best_matched")}</span>
                            </li>}
                        <li
                            role="button"
                            onClick={() => handleClick(-1)}
                            className={activeCategoryId === -1 ? "active_category" : ""}
                        >
                            <i className="fa fa-caret-right"></i>{" "}
                            <span>{t("JobByCategory.all_projects")}</span>
                        </li>
                        {categoriesData?.map((el) => (
                            <li
                                role="button"
                                onClick={() => handleClick(el.id)}
                                className={activeCategoryId === el.id ? "active_category" : ""}
                                key={el.id}
                            >
                                <i className="fa fa-caret-right"></i>{" "}
                                <span>{t(`CategoriesListDB.` + el.category.replace(/ /g, ''))}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default JobByCategory;