import React from 'react';
import {Route, Routes} from "react-router-dom";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import RegisterPage from "./pages/RegisterPage";
import ProfilePage from "./pages/ProfilePage";
import Layout from "./components/layout";
import ProjectPage from "./pages/ProjectPage";
import {AdminDashboardPage} from "./pages/AdminDashboardPage";
import AdminProfilePage from "./pages/AdminProfilePage";
import AdminProjectPage from "./pages/AdminProjectPage";
import Error404 from "./pages/Error404";
import './translations/config';
import AppliedProjectsPage from "./pages/AppliedProjectsPage";
import LikedProjectsPage from "./pages/LikedProjectsPage";
import MessengerPage from "./pages/MessengerPage";


function App() {

    return (
        <>
            <Routes>
                <Route path='/' element={<Layout/>}>
                    <Route index element={<HomePage/>}/>
                    <Route path="/profile" element={<ProfilePage/>}/>
                    <Route path="/applied_projects" element={<AppliedProjectsPage/>}/>
                    <Route path="/liked_projects" element={<LikedProjectsPage/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/sign-up" element={<RegisterPage/>}/>
                    <Route path="/projects/:projectId" element={<ProjectPage/>}/>
                    <Route path="/project/:projectId" element={<AdminProjectPage/>}/>
                    <Route path="/freelancer_profile/:freelancerId" element={<AdminProfilePage/>}/>
                    <Route path="/dashboard" element={<AdminDashboardPage/>}/>
                    <Route path="/messenger" element={<MessengerPage/>}/>
                    <Route path="*" element={<Error404/>}/>
                </Route>
            </Routes>
        </>
    );
}

export default App;
