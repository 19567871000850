import {ProjectsList} from "../components/ProjectsList";
import {useNavigate} from "react-router-dom";
import {useGetAllProjectsQuery} from "../store/api/projectApi";
import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from "react";

export const AdminDashboardPage = () => {
    const user = useAuth()

    const {
        data: projects,
        // isSuccess: isSuccessProjects
    } = useGetAllProjectsQuery()

    const navigate = useNavigate()

    const [uType, setUType] = useState<"admin" | undefined>(undefined);

    useEffect(() => {
        if (user && user?.type === "admin"){
            setUType("admin")
        }else if(user?.error){
            setUType(undefined)
            navigate("/")
        }
    }, [user]);

    return (
        <>
            {uType
                ?<div className="container">
                    <div className="row">
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                            <ProjectsList projects={projects} mode={uType}/>
                        </div>
                    </div>
                </div>
                : <p>Loading...</p>
            }
        </>
    )
}