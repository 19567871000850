import "./message.css";
import {FC} from "react";
import TimeAgo from "timeago-react";
import {IMessage} from "../../models/models";

interface MessageProps{
    message: IMessage
    own:boolean
}
 const Message: FC<MessageProps> = ({ message, own }) => {
  return (
    <div className={own ? "message own" : "message"}>
      <div className="messageTop">
        <p className="messageText">{message.message_text}</p>
      </div>
        {message.ts &&
            <div className="messageBottom"><TimeAgo datetime={message.ts} /></div>
        }
    </div>
  );
}
export default Message