import {ProjectsList} from "./ProjectsList";
import {useGetAppliedProjectsQuery} from "../store/api/applyProjectsApi";
import {useGetAllProjectsQuery} from "../store/api/projectApi";

const AppliedProjects = () => {

    const {data: appliedProjects} = useGetAppliedProjectsQuery()

    const {data: projects, isLoading} = useGetAllProjectsQuery(
        undefined, {
            skip: !appliedProjects,
            selectFromResult: ({data: projects, isError, isLoading}) => ({
                data: projects?.filter(item => appliedProjects?.project_ids?.includes(item.id as number)),
                isError,
                isLoading
            }),
        })
    if (isLoading) {
        return <p>Loading...</p>
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <ProjectsList projects={projects} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default AppliedProjects