import { Outlet } from 'react-router-dom';
import Footer from './Footer';
import Header from './Header';
import {userApi} from "../store/api/userApi";

const Layout = () => {
    const { isLoading, isFetching } = userApi.endpoints.getMe.useQuery(null, {
        skip: false,
        refetchOnMountOrArgChange: true,
    });

    if (isLoading || isFetching) {
        return <p>Loading...</p>;
    }

    return (
    <>
      <Header/>
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
