import "./chat.css";
import React, {useEffect, useState} from "react";
import {useAuth} from "../../hooks/useAuth";
import Message from "../message/Message";
import Conversation from "../conversations/Conversation";
import {useGetAllChatsQuery, useGetAllMessagesQuery, useSendMessageMutation} from "../../store/api/chatApi";
import {IChat, IMessage} from "../../models/models";

const Chat = () => {
    const user = useAuth();

    const [conversations, setConversations] = useState<IChat[]>([]);
    const [currentChat, setCurrentChat] = useState<number | null>(null);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [newMessage, setNewMessage] = useState("");
    const [userId, setUserId] = useState<number>(0);

    const [sendMessage] = useSendMessageMutation()
    const {
        data: messagesData,
        refetch
    } = useGetAllMessagesQuery(currentChat ?? 0,  {refetchOnMountOrArgChange: true})

    const {
        data: conversationsData,
        refetch: refetchGetAllChats
    } = useGetAllChatsQuery(userId,  {refetchOnMountOrArgChange: true})

    useEffect(() => {
        if (user && user.id) {
            setUserId(user.id)

        }
    }, [user]);

    useEffect(() => {
        if (conversationsData && conversationsData.length > 0) {
            setConversations(conversationsData)
            refetch()
        }
    }, [conversationsData]);

    useEffect(() => {
        if (messagesData && messagesData.length) {
            setMessages(messagesData)
        }else{
            setMessages([])
        }
    }, [messagesData]);



    const handleSubmit = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        if (userId && currentChat && newMessage.length > 0) {
            const message: IMessage = {
                sender_id: userId,
                chat_id: currentChat,
                message_text: newMessage,
            };

            try {
                await sendMessage(message).then(() => {
                    refetch()
                });
                setNewMessage("");
            } catch (err) {
                console.log(err);
            }
        }
    };

    useEffect(() => {
        refetchGetAllChats()
    }, [userId]);

    useEffect(() => {
        refetch()
    }, [currentChat]);



    return (
        <div className="container">
            <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="py-5">
                        {/*<p>chats</p>*/}
                        <div className="messenger">
                            <div className="chatMenu">
                                <div className="chatMenuWrapper">
                                    <h3 className="text-white">Chats</h3>
                                    {conversations && conversations.map((c) => (
                                        <div className={currentChat == c.chat_id ? "currentChat" : ""} key={c.chat_id} onClick={()=>setCurrentChat(c.chat_id)}>
                                            <Conversation conversation={c}/>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className="chatBox">
                                <div className="chatBoxWrapper">
                                    {currentChat ? (
                                        <>
                                            <div className="chatBoxTop">
                                                {messages.length > 0 && messages.map((m) => (
                                                    <div key={m.message_id}>
                                                        <Message message={m} own={m?.sender_id === user?.id}/>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="chatBoxBottom">
                                                <textarea
                                                    className="chatMessageInput"
                                                    placeholder="write something..."
                                                    onChange={(e) => setNewMessage(e.target.value)}
                                                    value={newMessage}
                                                ></textarea>
                                                <button className="chatSubmitButton" onClick={handleSubmit}>
                                                    Send
                                                </button>
                                            </div>
                                        </>
                                    ) : (
                                        <span className="noConversationText">
                                            Open a conversation to start a chat.
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Chat
