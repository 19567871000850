import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import {userApi} from './userApi';
import {LoginInput} from "../../components/LoginForm";
import {IFreelancer, IFreelancerSignUp} from "../../models/models";


export const authApi = createApi({
    reducerPath: 'authApi',
    baseQuery: customFetchBase,
    tagTypes: ['UserData'],
    endpoints: (builder) => ({
        registerUser: builder.mutation<IFreelancer, IFreelancerSignUp>({
            query(data) {
                return {
                    url: 'freelancers/sign-up',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags:  ['UserData']
        }),
        loginUser: builder.mutation<IFreelancer, LoginInput>({
            query(data) {
                return {
                    url: 'freelancers/login',
                    method: 'POST',
                    body: data,
                    credentials: 'include',
                };
            },
            invalidatesTags: ["UserData"],
            async onQueryStarted(args, {dispatch, queryFulfilled}) {
                try {
                    await queryFulfilled;
                    await dispatch(userApi.endpoints.getMe.initiate(null));
                } catch (error) {
                }
            },
        }),
        logoutUser: builder.mutation<void, void>({
            query() {
                return {
                    url: 'freelancers/logout',
                    credentials: 'include',
                };
            },
        }),
        // verifyEmail: builder.mutation<GenericResponse, string>({
        //   query(verificationCode) {
        //     return {
        //       url: `auth/verifyemail/${verificationCode}`,
        //       credentials: 'include',
        //     };
        //   },
        // }),
        // forgotPassword: builder.mutation<GenericResponse, { email: string }>({
        //   query(body) {
        //     return {
        //       url: `auth/forgotpassword`,
        //       method: 'POST',
        //       credentials: 'include',
        //       body,
        //     };
        //   },
        // }),
        // resetPassword: builder.mutation<GenericResponse, IResetPasswordRequest>({
        //   query({ resetToken, password, passwordConfirm }) {
        //     return {
        //       url: `auth/resetpassword/${resetToken}`,
        //       method: 'PATCH',
        //       body: { password, passwordConfirm },
        //       credentials: 'include',
        //     };
        //   },
        // }),
    }),
});

export const {
    useLoginUserMutation,
    useRegisterUserMutation,
    useLogoutUserMutation,
    // useVerifyEmailMutation,
    // useForgotPasswordMutation,
    // useResetPasswordMutation,
} = authApi;
