import {Logo} from "./Logo";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


const Footer = () => {
    const {t} = useTranslation()
    return (

        <div>
            <div className="jp_main_footer_img_wrapper">
                <div className="jp_newsletter_img_overlay_wrapper"></div>
                <div className="jp_footer_main_wrapper">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="jp_footer_logo_wrapper">
                                    <div className="jp_footer_logo">
                                        <Logo/>
                                    </div>
                                </div>
                            </div>
                            <div className="jp_footer_three_sec_main_wrapper">
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div className="row justify-content-start">
                                                <div className="col-8">
                                                    <div className="jp_footer_first_cont_wrapper">
                                                        <div className="jp_footer_first_cont">
                                                            <h2>{t("Footer.txt_1")}</h2>
                                                            <p>{t("Footer.txt_2")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div className="row justify-content-end">
                                                <div className="col-8">
                                                    <div
                                                        className="jp_footer_candidate_wrapper jp_footer_candidate_wrapper4">
                                                        <div className="jp_footer_candidate">
                                                            <h2>{t("Footer.txt_3")}</h2>
                                                            <ul>
                                                                <li><Link to="https://www.staryou.de/imprint"><i className="fa fa-caret-right"
                                                                                    aria-hidden="true"></i>{t("Footer.txt_4")} </Link>
                                                                </li>
                                                                <li><Link to="https://www.staryou.de/privacy"><i className="fa fa-caret-right"
                                                                                    aria-hidden="true"></i>{t("Footer.txt_5")}</Link>
                                                                </li>
                                                                <li><Link to="https://www.staryou.de/terms/b2c"><i className="fa fa-caret-right"
                                                                                    aria-hidden="true"></i>{t("Footer.txt_6")}</Link></li>
                                                                <li><Link to="https://www.staryou.de/terms/b2b"><i className="fa fa-caret-right"
                                                                                    aria-hidden="true"></i>{t("Footer.txt_7")}</Link></li>

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>


                            </div>
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="jp_bottom_footer_Wrapper">
                                    <div className="row">
                                        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <div className="jp_bottom_footer_left_cont">
                                                <p>{t("Footer.txt_8")}.</p>
                                            </div>

                                        </div>
                                        {/*<div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">*/}
                                        {/*    <div className="jp_bottom_footer_right_cont">*/}
                                        {/*        <ul>*/}
                                        {/*            <li><Link to="/"><i className="fa fa-facebook"></i></Link></li>*/}
                                        {/*            <li><Link to="/"><i className="fa fa-twitter"></i></Link></li>*/}
                                        {/*            <li><Link to="/"><i className="fa fa-pinterest-p"></i></Link></li>*/}
                                        {/*            <li><Link to="/"><i className="fa fa-linkedin"></i></Link></li>*/}
                                        {/*            <li className="hidden-xs"><Link to="/"><i*/}
                                        {/*                className="fa fa-google-plus"></i></Link></li>*/}
                                        {/*            <li className="hidden-xs"><Link to="/"><i*/}
                                        {/*                className="fa fa-vimeo"></i></Link></li>*/}
                                        {/*        </ul>*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Footer