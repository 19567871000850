import React, {FC, useState} from "react";
import ProjectItem from "./ProjectItem";
import {IProject} from "../models/models";

interface ProjectsProps {
    projects: IProject[] | undefined;
    currentPage: number
    setCurrentPage: (pageNumber: number) => void;
    error?: string
}

const Projects: FC<ProjectsProps> = ({projects, currentPage, setCurrentPage, error}) => {

    const [itemsPerPage] = useState(5);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = projects?.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };
    return (
        <>
            <div className="tab-content">
                <div className="ss_featured_products">
                    {currentItems && currentItems.length === 0 && error &&
                        <h3 className="text-danger text-center">{error}!</h3>
                    }

                    {currentItems && currentItems?.map((project) => (
                        <ProjectItem key={project.id} project={project}/>
                    ))}

                    {projects && projects.length > itemsPerPage && (
                        <div className="video_nav_img_wrapper">
                            <div className="video_nav_img">
                                <ul>
                                    {Array.from({length: Math.ceil(projects.length / itemsPerPage)}, (_, index) => (
                                        <li className="btn btn-info" key={index} onClick={() => paginate(index + 1)}
                                        >{index + 1}
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    )}

                </div>
            </div>
        </>
    )
}
export default Projects