import {Logo} from "./Logo";
import {Link} from "react-router-dom";

const BestMatchedJobs =()=>{
    return(
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="jp_add_resume_wrapper">
                <div className="jp_add_resume_img_overlay"></div>
                <div className="jp_add_resume_cont">
                    <Logo/>
                    <h4>Get Best Matched Jobs On your Email. Add Resume NOW!</h4>
                    <ul>
                        <li><Link to="/sign-up"><i className="fa fa-plus-circle"></i> &nbsp;ADD
                            RESUME</Link></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
export default BestMatchedJobs