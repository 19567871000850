import {fetchDownloadFile} from "../store/api/fileApi";
import {useQuery} from "react-query";
import {useTranslation} from "react-i18next";

const FileDownload =({fileName}: { fileName: string })=> {
    const {t} = useTranslation()
    const {data, isLoading, isError, error} = useQuery<Blob, Error>(
        ['fileDownload', fileName],
        () => fetchDownloadFile(fileName),
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (isError) {
        return <div>Error: {error?.message}</div>;
    }

    if (!data) {
        return null;
    }

    const downloadUrl = URL.createObjectURL(data);

    return (

        <a href={downloadUrl} download={fileName}>
            {t("FileDownload.download_file")}
        </a>

    );
}

export default FileDownload