import React, {FC, useEffect} from "react";
import {INewChat, IProject} from "../models/models";
import {useCreateChatMutation} from "../store/api/chatApi";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";


interface CreateChatBtnProps{
    project: IProject,
    invited_u_id: number,
}

const CreateChatBtn: FC<CreateChatBtnProps> = ({project, invited_u_id}) => {

    const {t} = useTranslation()
    const navigate = useNavigate()

    const [createChat, {isSuccess, data}] = useCreateChatMutation()

    const handleStartChatClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const newChatData: INewChat = {
            invited_u_id: invited_u_id,
            chat_name: "Chat by Project: " + project?.title,
        }
        createChat(newChatData)
    }
    useEffect(() => {
        if (data){
            navigate(`/messenger`)
        }
    }, [isSuccess]);

    return (
        <div>
            <button
                className="btn btn-primary w-100"
                onClick={(event) => handleStartChatClick(event)}
            >
                {t("CreateChatBtn.btnName")}
            </button>
        </div>
    )
}
export default CreateChatBtn