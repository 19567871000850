import {Logo} from "./Logo";
import {Link, useNavigate} from "react-router-dom";
import {useLogoutUserMutation} from "../store/api/authApi";
import {useEffect} from "react";
import {useAppDispatch} from "../hooks/redux";
import {logout} from "../store/slices/userSlice";
import {useAuth} from "../hooks/useAuth";
import {useTranslation} from "react-i18next";


const Header = () => {
    const {t} = useTranslation()
    const user = useAuth()
    const [logoutUser, {isLoading, isSuccess, error, isError}] = useLogoutUserMutation();
    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    useEffect(() => {
        if (isSuccess) {
            dispatch(logout())
            console.log("User logout successful")
            navigate("/login")
        }

        if (isError) {
            if (Array.isArray((error as any).data.error)) {
                (error as any).data.error.forEach((el: any) =>
                    console.log(el.message)
                );
            } else {
                console.log((error as any).data.message)
            }
        }
        // eslint-disable-next-line
    }, [isLoading]);

    const onLogoutHandler = async () => {
        await logoutUser();
    };

    return (
        <div className="gc_main_menu_wrapper">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-lg-2 col-md-2 col-sm-12 col-xs-12 hidden-xs hidden-sm full_width">
                        <div className="gc_header_wrapper">
                            <div className="gc_logo">
                                <Logo/>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-8 col-sm-12 col-xs-12 center_responsive">
                        <div className="header-area hidden-menu-bar stick" id="sticker">
                            <div className="mainmenu">
                                <ul className="float_left">
                                    <li className="has-mega gc_main_navigation">
                                        <Link to="/" className="gc_main_navigation">{t("Header.txt_1")}</Link>
                                    </li>
                                    {user && user?.id && user.type != "admin" &&
                                        <>
                                            <li className="has-mega gc_main_navigation">
                                                <Link to="/applied_projects"
                                                      className="gc_main_navigation">{t("Header.txt_2")}</Link>
                                            </li>
                                            <li className="parent gc_main_navigation">
                                                <Link to="/liked_projects"
                                                      className="gc_main_navigation">{t("Header.txt_3")}</Link>
                                            </li>
                                        </>
                                    }
                                    {user && user?.id &&
                                        <>
                                            <li className="has-mega gc_main_navigation">
                                                <Link to="/messenger"
                                                      className="gc_main_navigation">{t("Header.txt_4")}</Link>
                                            </li>
                                        </>
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-4 col-sm-12 col-xs-12 hidden-sm hidden-xs">
                        <div className="jp_navi_right_btn_wrapper">
                            <ul>
                                {user?.id && user?.type === "freelancer" &&
                                    <>
                                        <li><Link to="/profile"><i className="fa fa-user"></i>&nbsp; {t("Header.txt_5")}
                                        </Link></li>
                                        <li>
                                            <button onClick={onLogoutHandler}><i
                                                className="fa fa-sign-in"></i>&nbsp; {t("Header.txt_6")}
                                            </button>
                                        </li>
                                    </>
                                }
                                {user?.id && user?.type === "admin" &&
                                    <>
                                        <li><Link to="/dashboard">{t("Header.txt_7")}</Link></li>
                                        <li>
                                            <button onClick={onLogoutHandler}><i
                                                className="fa fa-sign-in"></i>&nbsp; {t("Header.txt_6")}
                                            </button>
                                        </li>
                                    </>
                                }
                                {(!user || user?.error) &&
                                    <>
                                        <li><Link to="/sign-up"><i className="fa fa-user"></i>&nbsp; {t("Header.txt_8")}
                                        </Link></li>
                                        <li><Link to="/login"><i
                                            className="fa fa-sign-in"></i>&nbsp; {t("Header.txt_9")}</Link></li>
                                    </>
                                }
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Header