import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import {IProject} from "../../models/models";

export const projectApi = createApi({
    reducerPath: 'projectApi',
    baseQuery: customFetchBase,
    tagTypes: ['Projects'],
    endpoints: (builder) => ({
        createProject: builder.mutation<IProject, IProject>({
            query(post) {
                return {
                    url: 'projects/add',
                    method: 'POST',
                    credentials: 'include',
                    body: post,
                };
            },
            invalidatesTags: ['Projects']
        }),
        getAllProjects: builder.query<IProject[], void>({
            query() {
                return {
                    url: `projects/get`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
            providesTags: () => ["Projects"]
        }),
        getProject: builder.query<IProject, string>({
            query(id) {
                return {
                    url: `projects/get/${id}`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
        }),
        updateProject: builder.mutation<IProject, IProject>(
            {
                query(projects) {
                    return {
                        url: `projects/update`,
                        method: 'PUT',
                        credentials: 'include',
                        body: projects,
                    };
                },
                invalidatesTags: ['Projects']
            }
        ),
        //
        deleteProject: builder.mutation<IProject, IProject>({
            query(project) {
                return {
                    url: `projects/delete/${project.id}`,
                    method: 'DELETE',
                    credentials: 'include',
                };
            },
            invalidatesTags: ['Projects']
        }),
    }),
});

export const {useGetAllProjectsQuery} = projectApi;
