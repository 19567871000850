import ProjectPage from "./ProjectPage";
import {useNavigate} from "react-router-dom";
import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from "react";


const AdminProjectPage = () =>{
    const user = useAuth()

    const navigate = useNavigate()

    const [uType, setUType] = useState<"admin" | undefined>(undefined);

    useEffect(() => {
        if (user && user?.type === "admin"){
            setUType("admin")
        }else if(user?.error){
            setUType(undefined)
            navigate("/")
        }
    }, [user]);

    return(
        <>
            <ProjectPage mode={uType}/>
        </>
    )
}
export default AdminProjectPage