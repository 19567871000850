import {useGetAllProjectsQuery} from "../store/api/projectApi";
import {useGetLikedProjectsQuery} from "../store/api/likedProjectsApi";
import {ProjectsList} from "./ProjectsList";

const LikedProjects = () => {

    const {data: likedProjects} = useGetLikedProjectsQuery()

    const {data: projects, isLoading} = useGetAllProjectsQuery(
        undefined, {
            skip: !likedProjects,
            selectFromResult: ({data: projects, isError, isLoading}) => ({
                data: projects?.filter(item => likedProjects?.project_ids?.includes(item.id as number)),
                isError,
                isLoading
            }),
        })
    if (isLoading) {
        return <p>Loading...</p>
    }

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <ProjectsList projects={projects} />
                    </div>
                </div>
            </div>
        </>
    )
}
export default LikedProjects