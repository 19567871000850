import Profile from "../components/Profile";
import {useNavigate, useParams} from "react-router-dom";
import {useGetFreelancerByIdQuery} from "../store/api/freelancersApi";
import {useAuth} from "../hooks/useAuth";
import {useEffect, useState} from "react";

const AdminProfilePage = () => {
    const user = useAuth()

    const {freelancerId} = useParams<string>();

    const {data} = useGetFreelancerByIdQuery(freelancerId ?? "", {skip: !freelancerId})

    const navigate = useNavigate()

    const [uType, setUType] = useState<"admin" | undefined>(undefined);

    useEffect(() => {
        if (user && user?.type === "admin"){
            setUType("admin")
        }else if(user?.error){
            setUType(undefined)
            navigate("/")
        }
    }, [user]);



    return (
        <>
            {user?.type === "admin" &&
                <Profile user={data} mode={uType}/>
            }
        </>
    )
}

export default AdminProfilePage