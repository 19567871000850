import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import {IFreelancer} from "../../models/models";

export const freelancerApi = createApi({
    reducerPath: 'freelancerApi',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({
        getAllFreelancers: builder.query<IFreelancer[], void>({
            query() {
                return {
                    url: `freelancers/get_all`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
        }),
        getAllAppliedFreelancers: builder.query<IFreelancer[], void>({
            query() {
                return {
                    url: `freelancers/get_applied_freelancers`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
        }),
        getFreelancerById: builder.query<IFreelancer, string>({
            query(id) {
                return {
                    url: `freelancers/get_by_id/${id}`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
        }),
    }),
});

export const {useGetAllFreelancersQuery, useGetAllAppliedFreelancersQuery, useGetFreelancerByIdQuery} = freelancerApi;
