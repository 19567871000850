import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";
import {store} from "./store/store";
import {Provider} from "react-redux";
import AuthMiddleware from "./Helpers/AuthMiddleware";
import {CookiesProvider} from "react-cookie";

import "./index.css"

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import './css/animate.css'
import './css/bootstrap.css'
import './css/font-awesome.css'
import './css/flaticon.css'
import './css/reset.css'
import './css/style.css'
import './css/responsive.css'


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <BrowserRouter>
            <CookiesProvider>
                <AuthMiddleware>
                    <App/>
                </AuthMiddleware>
            </CookiesProvider>
        </BrowserRouter>
    </Provider>
);
