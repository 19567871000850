import {Link} from "react-router-dom";
import React, {FC} from "react";
import {useTranslation} from "react-i18next";
import {useGetAllFreelancersQuery} from "../store/api/freelancersApi";
import {useGetMatchedFreelancerToProjectQuery} from "../store/api/openaiApi";
import CreateChatBtn from "./CreateChatBtn";
import {IProject} from "../models/models";

interface MatchedFreelancerToProjectProps {
    project: IProject
}

const MatchedFreelancerToProject: FC<MatchedFreelancerToProjectProps> = ({project}) => {
    const {t} = useTranslation()
    const {
        data: matchedFreelancers, isLoading: isLoadingMatchedFreelancer
    } = useGetMatchedFreelancerToProjectQuery(project.id)

    const {
        data: freelancers, isLoading
    } = useGetAllFreelancersQuery(
        undefined, {
            skip: !matchedFreelancers,
            selectFromResult: ({data: freelancers, isError, isLoading}) => ({
                data: freelancers?.filter(item => matchedFreelancers?.[0]?.id === item.id as number),
                isError,
                isLoading
            }),
        })

    if (isLoading || isLoadingMatchedFreelancer) {
        return <p>Loading...</p>;
    }


    return (
        <>
            <>
                <h3 className="text-white">{t("MatchedFreelancerToProject.txt_1")}</h3>
                <div className="row">
                    {freelancers && freelancers?.map(freelancer => (
                        <div className="col-md-4" key={freelancer.id}>
                            <div
                                className="me-2 p-3 my-2 border d-flex flex-column justify-content-between align-items-center">
                                {/*<p>IMAGE </p>*/}
                                <p>{t("AppliedFreelancers.full_name")}: {freelancer.first_name} - {freelancer.last_name} </p>
                                <p>{t("AppliedFreelancers.phone")}: {freelancer.phone}</p>
                                <div className="d-flex align-items-center">
                                    <Link className="btn btn-success me-3"
                                          to={`/freelancer_profile/${freelancer.id}`}>{t("AppliedFreelancers.open_profile")}</Link>
                                    <CreateChatBtn project={project} invited_u_id={freelancer.id}/>
                                </div>

                            </div>
                        </div>
                    ))
                    }
                </div>
            </>
        </>
    )
}
export default MatchedFreelancerToProject