import React, {useEffect, useState} from 'react';
import Select, {MultiValue, StylesConfig} from 'react-select';
import {
    useGetAllSkillsQuery,
    useGetFreelancerSkillsQuery, useUpdateFreelancerSkillsMutation
} from "../store/api/skillsApi";
import {IOption, ISkill} from "../models/models";

export default function SkillsSelector() {

    const [value, setValue] = useState<IOption[]>([]);
    const [options, setOptions] = useState<IOption[]>([]);

    const {data: skills, isSuccess: isSuccessSkills} = useGetAllSkillsQuery()
    const {data: userSkills, isSuccess: isSuccessUserSkills, refetch} = useGetFreelancerSkillsQuery()
    const [updateFreelancerSkill] = useUpdateFreelancerSkillsMutation()

    useEffect(() => {
        if (skills) {
            const optionsFromSkills: IOption[] = skills.map((el) => ({
                    value: el.id.toString(),
                    label: el.skill
                })
            )
            setOptions(optionsFromSkills)
        }
    }, [isSuccessSkills]);

    useEffect(() => {
        if (userSkills && userSkills.length > 0) {
            const userValues: IOption[] = userSkills.map((el) => ({
                    value: el.id.toString(),
                    label: el.skill
                })
            )
            setValue(userValues)
        }else{
            setValue([])
        }
    }, [userSkills]);

    const handleSelectChange = (newValue: MultiValue<IOption>) => {
        setValue(newValue as IOption[]);
    };

    const handleSelectBlur = () => {
        if (value.length > 0) {
            const selectedSkills: ISkill[] = value.map((el) => ({
                    id: parseInt(el.value),
                    skill: el.label
                })
            )
            updateFreelancerSkill(selectedSkills).then(() => refetch())
        }else{
            updateFreelancerSkill([] as ISkill[]).then(() => refetch())
        }
    };
    return (
        <div>
            {isSuccessUserSkills &&
                <Select
                    closeMenuOnSelect={false}
                    isMulti
                    value={value}
                    onChange={handleSelectChange}
                    onBlur={handleSelectBlur}
                    options={options}
                    styles={SelectSkillsStyles}
                />
            }
        </div>
    );
}
export const SelectSkillsStyles: StylesConfig<IOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: 'transparent' }),
    option: (styles, { isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? undefined
                : isSelected
                    ? "#ccc"
                    : isFocused
                        ? "#ccc"
                        : undefined,
            color: "black",
            cursor: isDisabled ? 'not-allowed' : 'default',

            ':active': {
                ...styles[':active'],
                backgroundColor: !isDisabled
                    ? isSelected
                        ? "#ccc"
                        : "#808080"
                    : undefined,
            },
        };
    },
    multiValue: (styles) => {

        return {
            ...styles,
            backgroundColor: "transparent",
            border: "1px solid #ffffff",
            padding:"0",
        };
    },
    multiValueLabel: (styles) => ({
        ...styles,
        color: "#fff",
        padding:"0",

    }),
    multiValueRemove: (styles) => ({
        ...styles,
        ':hover': {
            color: 'white',
        },
    }),
};