import React, {FC, useEffect, useState} from "react";
import {IFreelancer, IProject} from "../models/models";
import {
    useAddLikedProjectMutation,
    useGetLikedProjectsQuery,
    useRemoveLikedProjectMutation
} from "../store/api/likedProjectsApi";

interface LikeBtnProps {
    project: IProject;
    user: IFreelancer;
}

const LikeBtn: FC<LikeBtnProps> = ({project, user}) => {

    const [addLikedProject] = useAddLikedProjectMutation()
    const [removeLikedProject] = useRemoveLikedProjectMutation()
    const {data, isLoading} = useGetLikedProjectsQuery()

    const [isLiked, setIsLiked] = useState<boolean>(false);

    useEffect(() => {
        if (data?.project_ids) {
            setIsLiked(data?.project_ids?.includes(project?.id));
        }
    }, [data, user]);

    const handleClickAddToLiked = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        addLikedProject({project_id: project?.id});
        setIsLiked(true);
    };

    const handleClickRemoveFromLiked = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        removeLikedProject(project?.id);
        setIsLiked(false);
    };
    return (
        <>
            {!isLiked && (
                <button
                    disabled={isLoading}
                    className="like_btn"
                    onClick={handleClickAddToLiked}
                >
                    <i className="fa fa-heart-o"></i>
                </button>
            )}
            {isLiked && (
                <button
                    disabled={isLoading}
                    className="like_btn liked_project"
                    onClick={handleClickRemoveFromLiked}
                >
                    <i className="fa fa-heart-o"></i>
                </button>
            )}
        </>
    )
}
export default LikeBtn