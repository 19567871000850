import React, {FC, useEffect, useState} from "react";
import {
    useAddApplyProjectMutation,
    useGetAppliedProjectsQuery,
    useRemoveApplyProjectMutation
} from "../store/api/applyProjectsApi";
import {IFreelancer, IProject} from "../models/models";
import {useTranslation} from "react-i18next";

interface ApplyRemoveBtnsProps {
    project: IProject;
    mode?: string;
    user: IFreelancer;
}

const ApplyRemoveBtns: FC<ApplyRemoveBtnsProps> = ({project, mode, user}) => {

    const {t} = useTranslation()

    const [addApplyProject] = useAddApplyProjectMutation();
    const [removeApplyProject] = useRemoveApplyProjectMutation();
    const {data, isLoading} = useGetAppliedProjectsQuery(
        undefined,
        {skip: mode === "admin", refetchOnFocus: true, refetchOnMountOrArgChange: true},
    );

    const [isApply, setIsApply] = useState<boolean>(false);

    useEffect(() => {
        if (data?.project_ids) {
            setIsApply(data?.project_ids?.includes(project?.id));
        }
    }, [data, user]);

    const handleClickAddToApplied = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        addApplyProject({project_id: project?.id});
        setIsApply(true);
    };

    const handleClickRemoveToApplied = (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        event.preventDefault();
        removeApplyProject(project?.id);
        setIsApply(false);
    };

    return (
        <>
            {!isApply && (
                <button
                    disabled={isLoading}
                    className="isApply_btn_false"
                    onClick={handleClickAddToApplied}
                >
                    {t("ApplyRemoveBtns.apply")}
                </button>
            )}
            {isApply && (
                <button
                    disabled={isLoading}
                    className="isApply_btn_true"
                    onClick={handleClickRemoveToApplied}
                >
                    {t("ApplyRemoveBtns.remove")}
                </button>
            )}
        </>
    )
}
export default ApplyRemoveBtns