import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";


const Error404 = () => {
    const{t} = useTranslation()
    return (
        <div className="error_page">
            <div className="container">
                <div className="row">
                    <div className="col-md-10 col-md-offset-1 col-sm-12 col-xs-12 text-center">
                        <div className="error_page_cntnt">
                            <h2>
                                <span>4</span>
                                <span>0</span>
                                <span>4</span>
                            </h2>
                            <h3>{t("error404.message")}</h3>
                            <p><Link to="/">{t("error404.btn-message")}</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
)
}
export default Error404