import React, {FC, useState} from "react";
import {IType} from "../models/models";
import {useTranslation} from "react-i18next";


interface TypeFilterHomePageProps {
    chooseType: (typeId: number) => void;
    typesData?: IType[]
}

const TypeFilterHomePage: FC<TypeFilterHomePageProps> = ({chooseType, typesData}) => {

    const {t} = useTranslation()

    const [activeTypeId, setActiveTypeId] = useState<number>(0);

    const handleClick = (typeId: number) => {
        setActiveTypeId(typeId);
        chooseType(typeId);
    };

    return (
        <>
            <ul className="d-flex align-items-center justify-content-end type_filter_menu">
                <li
                    role="presentation"
                    onClick={() => handleClick(0)}
                    className={activeTypeId === 0 ? "type_item_active  d-flex align-items-center justify-content-center px-4 py-2" : "type_item d-flex align-items-center justify-content-center px-4 py-2"}
                    key={0}
                >
                    <span>{t("TypeFilterHomePage.all_types")}</span>
                </li>
                {typesData?.map((el) => (
                    <li
                        role="presentation"
                        onClick={() => handleClick(el.id)}
                        className={activeTypeId === el.id ? "type_item_active d-flex align-items-center justify-content-center px-4 py-2" : "type_item d-flex align-items-center justify-content-center px-4 py-2"}
                        key={el.id}
                    >
                        <span>{el.type.replace(",", " ")}</span>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default TypeFilterHomePage