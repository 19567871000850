// import DocViewer, {DocViewerRenderers} from "@cyntler/react-doc-viewer";
import React from 'react';
import {baseUrl} from "../store/api/customFetchBase";
// @ts-ignore
import FileViewer from 'react-file-viewer';

const FileRenderNew = ({fileName}: { fileName: string }) => {

    const file = `${baseUrl}cv_files/upload/${fileName}`
    const type = fileName.split(".").pop()

    {/* ---------------- DocViewer (maybe will working later (MS problem))----------  */}
    // const docs = [
    //     { uri: `${baseUrl}cv_files/upload/${fileName}` },
    // ];

    return (
        <div>
            <FileViewer
                className="file-viewer-style"
                fileType={type}
                filePath={file}
            />

            {/*<DocViewer*/}
            {/*    pluginRenderers={DocViewerRenderers}*/}
            {/*    documents={docs}*/}
            {/*    config={{*/}
            {/*        header: {*/}
            {/*            disableHeader: true,*/}
            {/*            disableFileName: true,*/}
            {/*            retainURLParams: false*/}
            {/*        }*/}
            {/*    }}*/}
            {/*    style={{height: 1100}}*/}
            {/*/>*/}
        </div>
    );
}


export default FileRenderNew

