import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase, {baseUrl} from './customFetchBase';

export const fileApi = createApi({
    reducerPath: 'fileApi',
    baseQuery: customFetchBase,
    endpoints: (builder) => ({
        saveFile: builder.mutation<{}, FormData>({
            query(data) {
                return {
                    url: `cv_files/save`,
                    method: 'POST',
                    credentials: 'include',
                    body: data,
                };
            },
        }),
    }),
});

export const {useSaveFileMutation} = fileApi;


export async function fetchDownloadFile(name: string) {
    const response = await fetch(`${baseUrl}/cv_files/upload/${name}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });

    if (!response.ok) {
        throw new Error('Failed to download file');
    }

    const blob = await response.blob();
    return blob;
}