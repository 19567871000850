import React, {FC, useEffect, useState} from 'react';
import {useDeleteImgMutation, useUpdateImgMutation} from "../store/api/userImgApi";
import {baseUrl} from "../store/api/customFetchBase";
import icons from "../images/content/cp1.png";

interface UserImageProps {
    userId?: number
}

const UserImage: FC<UserImageProps> = ({userId}) => {

    const imgURL = `${baseUrl}user_img/get/${userId}`

    const [blobURL, setBlobURL] = useState<string | undefined>(undefined);

    const [deleteImg] = useDeleteImgMutation()
    const [updateImg, {isLoading, data: dataUpdateImg}] = useUpdateImgMutation()

    useEffect(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        fetch(imgURL, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            signal,
        })
            .then((res) => {
                if (!res.ok) {
                    setBlobURL(undefined)
                }
                return res.blob();
            })
            .then((blob) => {
                if (blob && blob?.type.includes("image")) {
                    const imgURL = URL.createObjectURL(blob);
                    setBlobURL(imgURL)

                } else {
                    setBlobURL(undefined)
                }
            })
            .catch((error) => {
                setBlobURL(undefined)
                console.log("error fetch user img - ", error)
            });
        return () => controller.abort();
    }, [userId, dataUpdateImg?.success]);

    const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('img', file);
            setBlobURL(undefined);
            updateImg(formData);
        }
    };
    const handleDeleteImage = () => {
        setBlobURL(undefined);
        deleteImg();
        const fileInput = document.getElementById("upload") as HTMLInputElement;
        if (fileInput) {
            fileInput.value = "";
        }
    };

    return (
        <div className="img-wrapper" >

            <div>
                {blobURL && !isLoading
                    ?<img src={blobURL} alt="profile_img"/>
                    : <img src={icons} alt="profile_img"/>
                }
            </div>
            <div className="image-btn-group">
                <label className="text-white p-2 rounded btn btn-primary w-100" htmlFor="upload">Update Image</label>
                <input type="file" id="upload" accept="image/*" onChange={handleImageChange} hidden/>
                {blobURL && (
                    <button className="delete-img-btn   d-inline text-white  rounded btn btn-danger"
                            onClick={handleDeleteImage}><i className="fa fa-trash"></i></button>
                )}
            </div>

        </div>
    );
};

export default UserImage;
