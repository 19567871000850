import PageTitle from "../components/PageTitle";
import {useTranslation} from "react-i18next";
import LikedProjects from "../components/LikedProjects";
import {useAuth} from "../hooks/useAuth";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

const LikedProjectsPage = () => {
    const {t} = useTranslation()
    const user = useAuth()
    const navigate = useNavigate()


    useEffect(() => {
        if (user && user?.type === "admin"){
            navigate("/dashboard")
        }else if(user?.error){
            navigate("/")
        }
    }, [user]);

    const titleText: string = t("LikedProjectsPage.title")
    return (
        <>
            <PageTitle title={titleText}/>
            <LikedProjects/>
        </>
    )
}

export default LikedProjectsPage
