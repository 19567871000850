import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import global_en from './en/global.json';
import global_de from './de/global.json';

export const defaultNS = 'global_de';

export const resources = {
    en: {global_en,},
    de: {global_de,}
};

i18next.use(initReactI18next).init({
    interpolation: {escapeValue: false},
    lng: 'de',
    debug: true,
    resources,
    defaultNS,
    returnNull: false, // for develop time
});