import {FC} from "react";
import {IProject} from "../models/models";
import {useGetAllSkillsQuery} from "../store/api/skillsApi";


interface SkillsProps{
    project: IProject
}

const ProjectSkills: FC<SkillsProps> = ({project}) =>{
    const {data: skills} = useGetAllSkillsQuery(undefined, {
        selectFromResult: ({data: skills, isError, isLoading}) => ({
            data: skills?.filter(item => project?.skill_ids?.includes(item.id as number)),
            isError,
            isLoading
        }),
    })
    if (skills !== undefined && skills.length > 0){
        return(
            <div className="jp_job_post_keyword_wrapper">
                <ul>
                    <li><i className="fa fa-tags"></i>Skills:</li>
                    {skills?.map(el => (
                            <li key={el.id}>{el.skill}</li>
                        )
                    )}
                </ul>
            </div>
        )

    }else{
        return(<><div className="jp_job_post_keyword_wrapper">
            <ul>
                <li></li>
            </ul>
        </div></>)
    }

}

export default ProjectSkills