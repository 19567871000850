import {SubmitHandler, useForm} from 'react-hook-form';
import {object, string, TypeOf} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {Link, useNavigate} from 'react-router-dom';
import {useEffect, useState} from "react";
import {useLoginUserMutation} from "../store/api/authApi";
import {useAppDispatch} from "../hooks/redux";
import {setUser} from "../store/slices/userSlice";
import {useTranslation} from "react-i18next";

const loginSchema = object({
    email: string()
        .min(1, 'Email address is required')
        .email('Email Address is invalid'),
    password: string()
        .min(1, 'Password is required')
        .min(8, 'Password must be more than 8 characters')
        .max(32, 'Password must be less than 32 characters'),
});

export type LoginInput = TypeOf<typeof loginSchema>;

const LoginForm = () => {

    const {t} = useTranslation()

    const dispatch = useAppDispatch()

    const navigate = useNavigate()

    const [loginUser, {isLoading, isError, error, isSuccess, data}] =
        useLoginUserMutation();

    const {
        register, reset, handleSubmit
    } = useForm<LoginInput>({
        resolver: zodResolver(loginSchema),
    });

   useEffect(() => {
        if (isSuccess && data && data?.id) {
            reset()
            console.log('You successfully logged in');
            dispatch(setUser(data))
            navigate("/")
        }

        if (data && data?.error) {
            console.log("isError", data?.error)
        }
        // eslint-disable-next-line
    }, [data]);

    const onSubmitHandler: SubmitHandler<LoginInput> = async (values) => {
        await loginUser(values);
    };

    return (
        <div className="login_section">
            <div className="login_form_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8 col-md-12 col-lg-offset-2">
                            <h1>{t("LoginForm.txt_1")}</h1>
                            <div className="login_wrapper">
                                <div>
                                    {data?.error &&
                                        <div className="w-100 d-flex align-items-center text-danger border-2 border-danger p-2">Error - {data?.error}</div>
                                    }
                                </div>
                                <form onSubmit={handleSubmit(onSubmitHandler)}>
                                    <div className="formsix-pos">
                                        <div className="form-group i-email">
                                            <input type="email" {...register("email")}
                                                   className="form-control"
                                                   placeholder="Username*"/>

                                        </div>

                                    </div>
                                    <div className="formsix-e">
                                        <div className="form-group i-password">
                                            <input type="password" {...register('password')}
                                                   className="form-control"
                                                   placeholder="Password*"/>
                                        </div>
                                    </div>
                                    <div className="login_remember_box">
                                        <label className="control control--checkbox">{t("LoginForm.remember_me")}
                                            <input type="checkbox"/>
                                            <span className="control__indicator"></span>
                                        </label>
                                        <Link to="/" className="forget_password">
                                            {t("LoginForm.forgot_password")}
                                        </Link>
                                    </div>
                                    <div className="login_btn_wrapper">
                                        <button className="btn btn-primary login_btn"
                                                type="submit"

                                        >
                                            {t("LoginForm.login")}
                                        </button>
                                    </div>
                                </form>
                                <div className="login_message">
                                    <p>{t("LoginForm.txt_2")} <Link to="/sign-up"> {t("LoginForm.register_now")} </Link>
                                    </p>
                                </div>
                            </div>
                            <p>{t("LoginForm.txt_3")}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};

export default LoginForm;