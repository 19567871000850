import {FormProvider, SubmitHandler, useForm} from 'react-hook-form';
import {object, string, TypeOf, z} from 'zod';
import {zodResolver} from '@hookform/resolvers/zod';
import {Link, useNavigate} from 'react-router-dom';
import {useLoginUserMutation, useRegisterUserMutation} from "../store/api/authApi";
import React, {useEffect, useState} from "react";
import FileUpload from "./FileUpload";
import {useSaveFileMutation} from "../store/api/fileApi";
import {setUser} from "../store/slices/userSlice";
import {useAppDispatch} from "../hooks/redux";
import {useTranslation} from "react-i18next";
import {useGetAllSkillsQuery} from "../store/api/skillsApi";
import {IOption} from "../models/models";
import Select, {MultiValue} from "react-select";
import {SelectSkillsStyles} from "./SkillsSelector";

const MAX_FILE_SIZE = 1500000;
const ACCEPTED_TYPES = ["application/pdf", "application/msword", "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

const registerSchema = object({
    first_name: string().min(1, 'Full name is required').max(100),
    last_name: string().min(1, 'Full name is required').max(100),
    email: string()
        .min(1, 'Email address is required')
        .email('Email Address is invalid'),
    password: string()
        .min(1, 'Password is required')
        .min(8, 'Password must be more than 8 characters')
        .max(32, 'Password must be less than 32 characters'),
    passwordConfirm: string().min(1, 'Please confirm your password'),
    phone: string().min(8, "Not enough digits in the phone number").max(12),
    skills:  z.object({id: z.number(), skill: z.string()}).array().optional(),
    cv_path: z.instanceof(File)
        .refine((data) => ACCEPTED_TYPES.includes(data.type),
            {message: "Only pdf format are supported."})
        .refine((data) => data.size <= MAX_FILE_SIZE,
            {message: `Max image size is 15MB.`})
}).refine((data) => data.password === data.passwordConfirm, {
    path: ['passwordConfirm'],
    message: 'Passwords do not match',
});

export type RegisterInput = TypeOf<typeof registerSchema>;

const SignUpForm = () => {

    const {t} = useTranslation()
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const formData = new FormData();
    const [skillsOptions, setSkillsOptions] = useState<IOption[]>([]);
    const [skillsValue, setSkillsValue] = useState<IOption[]>([]);


    const methods = useForm<RegisterInput>({
        resolver: zodResolver(registerSchema),
    });

    const {
        reset,
        control,
        formState: {errors, isSubmitSuccessful},
        handleSubmit,
        register,
        setValue,
    } = methods

    const [registerUser, {isLoading, isSuccess, error, isError, data}] = useRegisterUserMutation();

    const [loginUser, {
        isLoading: isLoadingLoginUser,
        isError: isErrorLoginUser,
        error: errorLoginUser,
        isSuccess: isSuccessLoginUser,
        data: dataLoginUser
    }] = useLoginUserMutation();

    const [saveFile, {
        isLoading: isLoadingSaveFile,
        isSuccess: isSuccessSaveFile,
        error: errorSaveFile,
        isError: isErrorSaveFile,
        data: dataSaveFile
    }] = useSaveFileMutation();

    const {data: skills, isSuccess: isSuccessSkills} = useGetAllSkillsQuery()

    const onSubmitHandler: SubmitHandler<RegisterInput> = async (values) => {

        formData.append('cv_path', values.cv_path);
        // console.log(values)
        if (values.cv_path.name) {
            if (skillsValue.length > 0){
                values.skills = skillsValue.map((el) => ({
                        id: parseInt(el.value),
                        skill: el.label
                    })
                )
            }
            // console.log(values)
            const userData = {...values, cv_path: values.cv_path.name}
            await registerUser(userData).then(() => {
                saveFile(formData)
            });
            const {email, password} = values;
            await loginUser({email, password})
        }

    };

    useEffect(() => {
        if (isSuccessSaveFile) {
            // console.log("file saved successful")
        }
    }, [isSuccessSaveFile]);


    useEffect(() => {
        if (isSuccessLoginUser && dataLoginUser) {
            dispatch(setUser(dataLoginUser))
            navigate("/")
        }

        if (isError) {
            // console.log("data isError", data);
            if (Array.isArray((error as any).data?.error)) {
                (error as any).data.error.forEach((el: any) =>
                    console.log(el.message)
                );
            } else {
                console.log((error as any).data.message)

            }
        }
    }, [isLoadingLoginUser]);


    useEffect(() => {
        if (isSubmitSuccessful) {
            reset();
        }
    }, [isSubmitSuccessful]);

    const handleSelectChange = (newValue: MultiValue<IOption>) => {
        setSkillsValue(newValue as IOption[]);
    };

    useEffect(() => {
        if (skills) {
            const optionsFromSkills: IOption[] = skills.map((el) => ({
                    value: el.id.toString(),
                    label: el.skill
                })
            )
            setSkillsOptions(optionsFromSkills)
            setSkillsValue([])
        }
    }, [isSuccessSkills]);

    return (
        <div className="register_section">
            <div className="register_tab_wrapper">
                <div className="container">
                    <div className="row">
                        <div className="col-md-10 col-md-offset-1">
                            <div role="tabpanel">
                                <div className="tab-content">
                                    <div className="tab-pane fade in active register_left_form">

                                        <div className="jp_regiter_top_heading">
                                            <p>{t("SignUpForm.txt_1")}</p>
                                        </div>
                                        <div className="row">
                                            <FormProvider  {...methods}>
                                                <form onSubmit={methods.handleSubmit(onSubmitHandler)}>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <label htmlFor="">{t("SignUpForm.first_name")}</label>
                                                        <input type="text" {...methods.register("first_name")}
                                                               placeholder="First Name*"/>
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <label htmlFor="">{t("SignUpForm.last_name")}</label>
                                                        <input type="text" {...methods.register("last_name")}
                                                               placeholder="Last Name*"/>
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <label htmlFor="">Email</label>
                                                        <input type="email" {...methods.register("email")}
                                                               placeholder="Email*"/>
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <label htmlFor="">{t("SignUpForm.password")}</label>
                                                        <input type="password" {...methods.register("password")}
                                                               placeholder=" password*"/>
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <label htmlFor="">{t("SignUpForm.confirm_password")}</label>
                                                        <input type="password" {...methods.register("passwordConfirm")}
                                                               placeholder="re-enter password*"/>
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <label htmlFor="">{t("SignUpForm.phone_number")}</label>
                                                        <input type="tel" {...methods.register("phone")}
                                                               placeholder="Phone Number*"
                                                        />
                                                    </div>
                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <label htmlFor="">{t("SignUpForm.skills")}</label>
                                                        {isSuccessSkills &&
                                                            <Select
                                                                {...methods.register("skills")}
                                                                closeMenuOnSelect={false}
                                                                isMulti
                                                                value={skillsValue}
                                                                onChange={handleSelectChange}
                                                                options={skillsOptions}
                                                                styles={SelectSkillsStyles}
                                                            />
                                                        }
                                                    </div>

                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12 custom_input"
                                                    >
                                                        <FileUpload name="cv_path" errors={errors} control={control}/>
                                                    </div>
                                                    <div
                                                        className="login_btn_wrapper register_btn_wrapper login_wrapper ">
                                                        <button type="submit"
                                                                className="btn btn-primary login_btn">{t("SignUpForm.register")}
                                                        </button>
                                                    </div>
                                                </form>
                                            </FormProvider>
                                        </div>
                                        <div className="login_message">
                                            <p>{t("SignUpForm.txt_2")}<Link
                                                to="/login">{t("SignUpForm.login_here")}</Link></p>
                                        </div>
                                    </div>

                                </div>
                                <p className="btm_txt_register_form">{t("SignUpForm.txt_3")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SignUpForm