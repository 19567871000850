import {configureStore} from '@reduxjs/toolkit';
import {projectApi} from './api/projectApi';
import userReducer from './slices/userSlice';
import {authApi} from "./api/authApi";
import {userApi} from "./api/userApi";
import {skillApi} from "./api/skillsApi";
import {fileApi} from "./api/fileApi";
import {freelancerApi} from "./api/freelancersApi";
import {setupListeners} from "@reduxjs/toolkit/query";
import {applyProjectApi} from "./api/applyProjectsApi";
import {categoryApi} from "./api/categoryApi";
import {openaiApi} from "./api/openaiApi";
import {likedProjectApi} from "./api/likedProjectsApi";
import {typeApi} from "./api/typeApi";
import {userImgApi} from "./api/userImgApi";
import {chatApi} from "./api/chatApi";

export const store = configureStore({
    reducer: {
        [authApi.reducerPath]: authApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        [projectApi.reducerPath]: projectApi.reducer,
        [skillApi.reducerPath]: skillApi.reducer,
        [fileApi.reducerPath]: fileApi.reducer,
        [freelancerApi.reducerPath]: freelancerApi.reducer,
        [applyProjectApi.reducerPath]: applyProjectApi.reducer,
        [categoryApi.reducerPath]: categoryApi.reducer,
        [openaiApi.reducerPath]: openaiApi.reducer,
        [likedProjectApi.reducerPath]: likedProjectApi.reducer,
        [typeApi.reducerPath]: typeApi.reducer,
        [userImgApi.reducerPath]: userImgApi.reducer,
        [chatApi.reducerPath]: chatApi.reducer,
        userState: userReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({}).concat([
            authApi.middleware,
            userApi.middleware,
            projectApi.middleware,
            skillApi.middleware,
            fileApi.middleware,
            freelancerApi.middleware,
            applyProjectApi.middleware,
            categoryApi.middleware,
            openaiApi.middleware,
            likedProjectApi.middleware,
            typeApi.middleware,
            userImgApi.middleware,
            chatApi.middleware,
        ]),
});

setupListeners(store.dispatch)

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

