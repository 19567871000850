import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import {ISkill} from "../../models/models";

export const skillApi = createApi({
    reducerPath: 'skillApi',
    baseQuery: customFetchBase,
    tagTypes: ['UserSkills'],
    endpoints: (builder) => ({
        getAllSkills: builder.query<ISkill[], void>({
            query() {
                return {
                    url: `get_skills`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
        }),
        getFreelancerSkills: builder.query<ISkill[], void>({
            query() {
                return {
                    url: `freelancers/get_skills`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
            providesTags: () => ["UserSkills"]
        }),
        updateFreelancerSkills: builder.mutation<any, ISkill[]>({
            query(data) {
                return {
                    url: `freelancers/update_skills`,
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
            invalidatesTags: ['UserSkills']
        }),

    }),
});

export const {useGetAllSkillsQuery, useGetFreelancerSkillsQuery, useUpdateFreelancerSkillsMutation} = skillApi;
