import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

export const userImgApi = createApi({
    reducerPath: 'userImgApi',
    baseQuery: customFetchBase,
    tagTypes: ['userImg'],
    endpoints: (builder) => ({
        deleteImg: builder.mutation<any, void>({
            query(data) {
                return {
                    url: `user_img/delete`,
                    method: 'DELETE',
                    credentials: 'include',
                    body: data,
                };
            },
        }),
        updateImg: builder.mutation<any, FormData>({
            query(data) {
                return {
                    url: `user_img/update`,
                    method: 'POST',
                    credentials: 'include',
                    body: data,
                };
            },
        }),
    }),
});

export const {useUpdateImgMutation, useDeleteImgMutation} = userImgApi;