import PageTitle from "../components/PageTitle";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {useAuth} from "../hooks/useAuth";
import {useTranslation} from "react-i18next";
import Chat from "../components/chat/Chat";

const MessengerPage = () => {

    const {t} = useTranslation()
    const user = useAuth()
    const navigate = useNavigate()

    useEffect(() => {
       if(user?.error){
            navigate("/")
        }
    }, [user]);

    const title = t("MessengerPage.title")
    return (
        <>
            <PageTitle title={title}/>
            <Chat/>
        </>
    )
}

export default MessengerPage