import Projects from "./Projects";
import BestMatchedJobs from "./BestMatchedJobs";
import JobByCategory from "./JobByCategory";
import {IProject} from "../models/models";
import React, {useEffect, useState} from "react";
import {useGetAllProjectsQuery} from "../store/api/projectApi";
import {useGetAllCategoryQuery} from "../store/api/categoryApi";
import {useAuth} from "../hooks/useAuth";
import {useGetMatchedProjectsToFreelancerQuery} from "../store/api/openaiApi";
import TypeFilterHomePage from "./TypeFilterHomePage";
import {useGetAllTypesQuery} from "../store/api/typeApi";
import {useTranslation} from "react-i18next";


const MainBlockHomePage = () => {

    const user = useAuth()

    const {t} = useTranslation()

    const {data: projects, isLoading: isLoadingProjects, isSuccess: isSuccessProjects} = useGetAllProjectsQuery()
    const {data: categoriesData} = useGetAllCategoryQuery()
    const {data: typesData} = useGetAllTypesQuery();
    const [currentProjects, setCurrentProjects] = useState<IProject[] | undefined>([]);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [selectedType, setSelectedType] = useState<number>(0);
    const [selectedCategory, setSelectedCategory] = useState<number>(-1);

    useEffect(() => {
        if (isSuccessProjects) {
            setCurrentProjects(projects);
        }
    }, [isLoadingProjects]);

    const {
        data: matchedProjects,
        isLoading: isLoadingMatchedProjects
    } = useGetMatchedProjectsToFreelancerQuery(user?.id ?? 0, {skip: !user?.id || user?.type === "admin"});


    const chooseCategory = (categoryId: number) => {
        setSelectedCategory(categoryId);
        setCurrentPage(1);

        if (categoryId === 0 && matchedProjects) {
            if (selectedType) {
                setCurrentProjects(projects?.filter(el => el.id === matchedProjects?.[0]?.id && el.type_id === selectedType));
            } else {
                setCurrentProjects(projects?.filter(el => el.id === matchedProjects?.[0]?.id));
            }
            return;
        }

        if (categoryId === -1) {
            if (selectedType) {
                setCurrentProjects(projects?.filter(el => el.type_id === selectedType));
            } else {
                setCurrentProjects(projects);
            }
            return;
        }

        if (selectedType) {
            setCurrentProjects(projects?.filter(el => el?.category_id === categoryId && el.type_id === selectedType));
        } else {
            setCurrentProjects(projects?.filter(el => el?.category_id === categoryId));
        }
    };

    const chooseType = (typeId: number) => {
        setSelectedType(typeId);
        setCurrentPage(1);

        if (selectedCategory === 0 && typeId === 0 && matchedProjects) {
            setCurrentProjects(projects?.filter(el => el.id === matchedProjects?.[0]?.id));
            return;
        }
        if (selectedCategory === 0 && typeId != 0 && matchedProjects) {
            setCurrentProjects(projects?.filter(el => el.type_id === typeId && el.id === matchedProjects?.[0]?.id));
            return;
        }
        if (selectedCategory === -1 && typeId === 0) {
            setCurrentProjects(projects);
            return;
        }
        if (selectedCategory && typeId === 0) {
            setCurrentProjects(projects?.filter(el => el?.category_id === selectedCategory));
            return;
        }
        if (selectedCategory === -1 && typeId !== 0) {
            setCurrentProjects(projects?.filter(el => el.type_id === typeId));
            return;
        }
        if (selectedCategory !== -1 && selectedCategory !== 0 && typeId !== 0) {
            setCurrentProjects(projects?.filter(el => el.type_id === typeId && el?.category_id === selectedCategory));
            return;
        }
    };

    return (
        <div className="jp_first_sidebar_main_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12">
                        <div className="row">
                            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <div className="cc_featured_product_main_wrapper">
                                    <div className="jp_hiring_heading_wrapper jp_job_post_heading_wrapper">
                                        <h2>{t("MainBlockHomePage.recent_jobs")}</h2>
                                    </div>
                                    <TypeFilterHomePage typesData={typesData} chooseType={chooseType}/>
                                </div>
                                {isLoadingProjects || isLoadingMatchedProjects
                                    ? <p>Loading...</p>
                                    : <Projects error={matchedProjects && matchedProjects?.[0]?.error}
                                                setCurrentPage={setCurrentPage} currentPage={currentPage}
                                                projects={currentProjects}/>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                        <div className="jp_first_right_sidebar_main_wrapper">
                            <div className="row">
                                {user?.error &&
                                    <BestMatchedJobs/>
                                }
                                {user &&
                                    <JobByCategory user={user} chooseCategory={chooseCategory}
                                                   categoriesData={categoriesData}/>
                                }
                                {/*{user?.error*/}
                                {/*    ? <JobSpotlight mode={true}/>*/}
                                {/*    : <JobSpotlight mode={false}/>*/}
                                {/*}*/}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default MainBlockHomePage