import {Link} from "react-router-dom";
import {IProject, IType} from "../models/models";
import React, {FC} from "react";
import ProjectSkills from "./ProjectSkills";
import AppliedFreelancers from "./AppliedFreelancers";
import {useAuth} from "../hooks/useAuth";
import ApplyRemoveBtns from "./ApplyRemoveBtns";
import LikeBtn from "./LikeBtn";
import {useGetAllTypesQuery} from "../store/api/typeApi";

interface ProjectItemProps {
    project: IProject;
    mode?: string;
}

const ProjectItem: FC<ProjectItemProps> = ({project, mode}) => {

    const {data: types} = useGetAllTypesQuery(undefined, {
        selectFromResult: ({data: types}) => ({
            data: types?.filter((item: IType) => project?.type_id === item.id)

        }),
    });

    const user = useAuth()

    let projectUrl: string;

    if (mode && mode === "admin") {
        projectUrl = `/project/${project.id}`;
    } else {
        projectUrl = `/projects/${project.id}`;
    }


    return (
        <div className="jp_job_post_main_wrapper_cont" key={project.id}>
            <Link to={projectUrl}>
                <div className="jp_job_post_main_wrapper">
                    <div className="row">
                        <div className="col-lg-8 col-md-8 col-sm-8 col-xs-12">
                            <div className="jp_job_post_right_cont">
                                <h4>{project.description}</h4>
                                <p>{project.contact}</p>
                                <ul>
                                    <li>
                                        <i className="fa fa-cc-paypal"></i>&nbsp;{" "}
                                        {project.hourly_rate}
                                    </li>
                                    <li>
                                        <i className="fa fa-map-marker"></i>&nbsp;{" "}
                                        {project.location}
                                    </li>
                                </ul>
                            </div>
                        </div>
                        {user && user?.type !== "admin" && !user?.error && user?.id && (
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="jp_job_post_right_btn_wrapper">
                                    <ul>
                                        <li>
                                            <LikeBtn project={project} user={user}/>
                                        </li>

                                        <li>
                                            <button className="pr_type_info">{types?.[0]?.type.replace(",", " ")}</button>
                                        </li>
                                        <li>
                                            <ApplyRemoveBtns project={project} user={user}/>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        )}
                        {mode && mode === "admin" && (
                            <div className="col-lg-4 col-md-4 col-sm-4 col-xs-12">
                                <div className="jp_job_post_right_btn_wrapper">
                                    <AppliedFreelancers viewType="list" project={project}/>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </Link>
            <ProjectSkills project={project}/>
        </div>
    );
};

export default ProjectItem;
