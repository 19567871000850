import {Link} from "react-router-dom";
import {FC} from "react";


interface PageTitleProps {
    title: string
}

const PageTitle: FC<PageTitleProps> = ({title}) => {
    return (
        <div className="jp_tittle_main_wrapper">
            <div className="jp_tittle_img_overlay"></div>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="jp_tittle_heading_wrapper">
                            <div className="jp_tittle_heading">
                                <h2>{title}</h2>
                            </div>
                            <div className="jp_tittle_breadcrumb_main_wrapper">
                                <div className="jp_tittle_breadcrumb_wrapper">
                                    <ul>
                                        <li><Link to="/">Home</Link> <i className="fa fa-angle-right"></i></li>
                                        <li>{title}</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PageTitle