import {Document, Page, pdfjs} from 'react-pdf'
import React, {useState} from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import {baseUrl} from "../store/api/customFetchBase";
import {useTranslation} from "react-i18next";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const FileRender = ({fileName}: { fileName: string }) => {

    const {t} = useTranslation()

    const [numPages, setNumPages] = useState<number>(1);
    const [pageNumber, setPageNumber] = useState<number>(1);

    const onDocumentLoadSuccess = ({numPages}: any)=> {
        setNumPages(numPages);
    }

    const goToPrevPage = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1);
        }
    }
    const goToNextPage = () => {
        if (pageNumber !== numPages)
            setPageNumber(pageNumber + 1);
    }

    return (

        <div>

            <Document file={`${baseUrl}/cv_files/upload/${fileName}`} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber}/>
            </Document>
            {numPages > 1 &&
                <div className="d-flex justify-content-center align-items-center mx-auto my-3">
                    <div>
                        <button className="btn btn-info" onClick={goToPrevPage}>{`< `}{t("FileRender.prev")}</button>
                    </div>
                    <div className="mx-2">
                        <span>
                        {t("FileRender.page")} {pageNumber} {t("FileRender.of")} {numPages}
                    </span></div>
                    <div>
                        <button className="btn btn-info" onClick={goToNextPage}>{t("FileRender.next")}{` >`}</button>
                    </div>
                </div>
            }

        </div>
    )
}


export default FileRender

