import PageTitle from "../components/PageTitle";
import SignUpForm from "../components/SingUpForm";
import {useAppSelector} from "../hooks/redux";
import {useNavigate} from "react-router-dom";
import {useCallback, useEffect} from "react";
import {useTranslation} from "react-i18next";

const RegisterPage = () => {

    const {t} = useTranslation()

    const user = useAppSelector((state) => state.userState.user);
    const navigate = useNavigate();

    const handleNavigation = useCallback(() => {
        navigate("/");
    }, [navigate]);

    useEffect(() => {
        if (user && user.id) {
            handleNavigation();
        }
    }, [user, handleNavigation]);

    const titleText: string = t("RegisterPage.sign_up")

    return (
        <>
            <PageTitle title={titleText}/>
            <SignUpForm/>
        </>
    )
}

export default RegisterPage