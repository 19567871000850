import React, {FC, useCallback} from 'react';
import {useController} from 'react-hook-form';
import {useTranslation} from "react-i18next";

interface FileUploadProps {
    name: string
    errors: any
    control: any
}

const FileUpload: FC<FileUploadProps> = ({name, errors, control}) => {

    const {t} = useTranslation()

    const {field} = useController({name, control});

    const onFileUploaderChange = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.files?.[0]) {
            field.onChange(event.target.files[0]);
        }
    }, []);

    return (
        < >
            <label>{t("FileUpload.file_uploader")}</label>
            <input type="file" onChange={onFileUploaderChange}/>
            <p>{errors[name]?.message}</p>
        </>
    );
}

export default FileUpload