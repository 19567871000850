import {Link} from "react-router-dom";

const FindJob = () => {
    return (
        <div className="jp_banner_heading_cont_wrapper">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="jp_job_heading_wrapper">
                            <div className="jp_job_heading">
                                <h1><span>3,000+</span> Browse Jobs</h1>
                                <p>Find Jobs, Employment & Career Opportunities</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="jp_header_form_wrapper">
                            <div className="col-lg-4 col-md-4 px-4 col-sm-12 col-xs-12">
                                <input type="text" placeholder="Keyword e.g. (Job Title, Description, Tags)"/>
                            </div>
                            <div className="col-lg-3 col-md-3 px-4 position-relative col-sm-12 col-xs-12">
                                <div className="jp_form_location_wrapper">
                                    <i className="fa fa-dot-circle-o first_icon"></i><select>
                                    <option>Select Location</option>
                                    <option>Select Location</option>
                                    <option>Select Location</option>
                                    <option>Select Location</option>
                                    <option>Select Location</option>
                                </select><i className="fa fa-angle-down second_icon"></i>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-3 px-4 position-relative col-sm-12 col-xs-12">
                                <div className="jp_form_exper_wrapper">
                                    <i className="fa fa-dot-circle-o first_icon"></i><select>
                                    <option>Experience</option>
                                    <option>Experience</option>
                                    <option>Experience</option>
                                    <option>Experience</option>
                                    <option>Experience</option>
                                </select><i className="fa fa-angle-down second_icon"></i>
                                </div>
                            </div>
                            <div className="col-lg-2 col-md-2 px-4 col-sm-12 col-xs-12">
                                <div className="jp_form_btn_wrapper">
                                    <ul>
                                        <li><Link to="/"><i className="fa fa-search"></i> Search</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <div className="jp_banner_main_jobs_wrapper">
                            <div className="jp_banner_main_jobs">
                                <ul>
                                    <li><i className="fa fa-tags"></i> Trending Keywords :</li>
                                    <li><Link to="/">ui designer,</Link></li>
                                    <li><Link to="/">developer,</Link></li>
                                    <li><Link to="/">senior</Link></li>
                                    <li><Link to="/">it company,</Link></li>
                                    <li><Link to="/">design,</Link></li>
                                    <li><Link to="/">call center</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default FindJob