import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';


interface OpenaiApiResp {
    id?: number
    error?: string
}

export const openaiApi = createApi({
    reducerPath: 'openaiApi',
    baseQuery: customFetchBase,
    tagTypes: ['MatchedToProject', 'MatchedToFreelancer'],
    endpoints: (builder) => ({
        getMatchedFreelancerToProject: builder.query<OpenaiApiResp[], number>({
            query(id) {
                return {
                    url: `open_ai/get_matched_freelancers/${id}`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
            providesTags: () => ["MatchedToProject"]
        }),
        getMatchedProjectsToFreelancer: builder.query<OpenaiApiResp[], number>({
            query(id) {
                return {
                    url: `open_ai/get_matched_projects/${id}`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
            providesTags: () => ["MatchedToFreelancer"]
        }),
    }),
});

export const {useGetMatchedFreelancerToProjectQuery, useGetMatchedProjectsToFreelancerQuery} = openaiApi;
