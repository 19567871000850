import {Link} from "react-router-dom";
import FileDownload from "./FileDownload";
import {QueryClient, QueryClientProvider} from "react-query";
import {FC} from "react";
import {IFreelancer} from "../models/models";
import FileRenderNew from "./FileRenderNew";
import FileRender from "./FileRender";
import {useTranslation} from "react-i18next";
import UserImage from "./UserImage";
import SkillsSelector from "./SkillsSelector";

const queryClient = new QueryClient();

interface ProfileProps {
    user?: IFreelancer | null
    mode?: string
}

const Profile: FC<ProfileProps> = ({user, mode}) => {
    const {t} = useTranslation()

    const fileType = user?.cv_path?.split(".").pop();

    return (
        <div className="jp_cp_profile_main_wrapper">
            <QueryClientProvider client={queryClient}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                            <div className="jp_cp_left_side_wrapper">
                                <div className="jp_cp_left_pro_wallpaper">
                                    <UserImage userId={user?.id}/>
                                    <h2>{user?.first_name} {user?.last_name}</h2>
                                    {/* TODO <p>something like title for freelancer do we need it UI/UX Designer in Dewas</p>*/}
                                    {/*<ul>*/}
                                    {/*    <li><Link to='/'><i className="fa fa-facebook"></i></Link></li>*/}
                                    {/*    <li><Link to='/'><i className="fa fa-twitter"></i></Link></li>*/}
                                    {/*    <li><Link to='/'><i className="fa fa-youtube-play"></i></Link></li>*/}
                                    {/*</ul>*/}
                                </div>
                                <div className="jp_cp_rd_wrapper">
                                    <ul>
                                        <li>
                                            {user?.cv_path &&
                                                <FileDownload fileName={`${user.cv_path}`}/>
                                            }
                                        </li>
                                        <li><Link to='/'><i
                                            className="fa fa-phone"></i> &nbsp;{t("Profile.contact_candidate")}</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-8 col-sm-12 col-xs-12">
                            <div className="jp_cp_right_side_wrapper">
                                <div className="jp_cp_right_side_inner_wrapper">
                                    <h2>{t("Profile.personal_details")}</h2>
                                    <table>
                                        {user?.id &&
                                            <tbody>
                                            <tr>
                                                <td className="td-w20">{t("Profile.full_name")}</td>
                                                <td className="td-w5">:</td>
                                                <td className="td-w75">{user.first_name} {user.last_name}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w20">Email</td>
                                                <td className="td-w5">:</td>
                                                <td className="td-w75">{user.email}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w20">{t("Profile.phone")}</td>
                                                <td className="td-w5">:</td>
                                                <td className="td-w75">{user.phone}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w20">{t("Profile.cv_file")}</td>
                                                <td className="td-w5">:</td>
                                                <td className="td-w75">{user.cv_path}</td>
                                            </tr>
                                            <tr>
                                                <td className="td-w20 align-top pt-2">{t("Profile.skills")}</td>
                                                <td className="td-w5 align-top pt-2">:</td>
                                                <td className="td-w75  skills-selector pt-1">
                                                    <SkillsSelector/>
                                                </td>
                                            </tr>
                                            </tbody>
                                        }
                                    </table>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">
                                    {fileType && fileType === "docx"
                                        ? <FileRenderNew fileName={`${user?.cv_path}`}/>
                                        : <div className="jp_cp_accor_heading_wrapper"><FileRender
                                            fileName={`${user?.cv_path}`}/></div>
                                    }
                                </div>

                            </div>
                            {/*<div className="row">*/}
                            {/*    <div className="col-lg-12 col-md-12 col-xs-12 col-sm-12">*/}
                            {/*        <div className="jp_cp_accor_heading_wrapper">*/}
                            {/*            <h2>Education</h2>*/}
                            {/*            <p>Proin gravida nibh vel velit quet. Aenean sollicitudin, lorem quis bibendum*/}
                            {/*                auctor, nisi elit consequat ipsum, nec sagittis sem nibh id elit. Duis sed*/}
                            {/*                odio*/}
                            {/*                sit amet nibh vulpuate cursus a sit amet mauris. Morbi accumsan ipsum velit.*/}
                            {/*                Nam*/}
                            {/*                nec tellus a odio tincidunt.</p>*/}
                            {/*        </div>*/}
                            {/*    </div>*/}

                            {/*</div>*/}

                        </div>
                    </div>
                </div>
            </QueryClientProvider>
        </div>
    )
}
export default Profile
