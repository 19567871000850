import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import {IType} from "../../models/models";

export const typeApi = createApi({
    reducerPath: 'typeApi',
    baseQuery: customFetchBase,
    tagTypes: ['Types'],
    endpoints: (builder) => ({
        getAllTypes: builder.query<IType[], void>({
            query() {
                return {
                    url: `types/get_types`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
            providesTags: () => ["Types"]
        }),
    }),
});

export const {useGetAllTypesQuery} = typeApi;
