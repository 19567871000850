import {Link} from "react-router-dom";


export const Logo = () => {
    return (
        <>
            {/*<img src="/" alt="logo"/>*/}
            <Link to="/"><h1 className="text-white fs-1 fw-bold">STARYOU2</h1></Link>
        </>
    )
}