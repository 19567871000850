import {FC} from "react";
import "./conversation.css";

interface ConversationProps{
  conversation: any,
}

const Conversation: FC<ConversationProps> =({ conversation })=> {

  return (
    <div className="conversation">
      <span className="conversationName">{conversation?.chat_name}</span>
    </div>
  );
}
export default Conversation