import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import {ICategory} from "../../models/models";

export const categoryApi = createApi({
    reducerPath: 'categoryApi',
    baseQuery: customFetchBase,
    tagTypes: ['Categories'],
    endpoints: (builder) => ({
        getAllCategory: builder.query<ICategory[], void>({
            query() {
                return {
                    url: `get_categories`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
            providesTags: () => ["Categories"]
        }),
    }),
});

export const {useGetAllCategoryQuery} = categoryApi;
