import {useGetAllAppliedFreelancersQuery} from "../store/api/freelancersApi";
import React, {FC} from "react";
import {Link, useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {IProject} from "../models/models";
import CreateChatBtn from "./CreateChatBtn";

interface AppliedFreelancersProps {
    project: IProject
    viewType: string
}

const AppliedFreelancers: FC<AppliedFreelancersProps> = ({project, viewType}) => {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const {
        data: freelancers
    } = useGetAllAppliedFreelancersQuery()

    const handleClick = ( event: React.MouseEvent<HTMLButtonElement>, fr_id: number) => {
        event.preventDefault();
        navigate(`/freelancer_profile/${fr_id}`)
    }

    return (
        <>
            {viewType && viewType === "list" &&
                <>
                    <div className="row">
                        {freelancers &&
                            freelancers.filter(freelancer => freelancer.projects_id?.includes(project?.id))
                                .map(freelancer => (
                                    <div className="col-6" key={freelancer.id}>
                                        <div
                                            className="me-2 p-3 my-2 border d-flex flex-column justify-content-between align-items-center">
                                            {/*<p>Image</p>*/}
                                            <p>{t("AppliedFreelancers.full_name")}: {freelancer.first_name} - {freelancer.last_name} </p>
                                            <div className="d-flex align-items-center justify-content-sm-around flex-column">
                                                <button
                                                    className="btn btn-success mb-3"
                                                    onClick={(event) => handleClick(event, freelancer.id)}
                                                >
                                                    {t("AppliedFreelancers.open_profile")}
                                                </button>
                                                <CreateChatBtn project={project} invited_u_id={freelancer.id}/>

                                            </div>
                                        </div>

                                    </div>
                                ))
                        }
                    </div>
                </>
            }
            {viewType && viewType === "block" &&
                <>
                    <h3 className="text-white">{t("AppliedFreelancers.txt_1")}</h3>
                    <div className="row">
                        {freelancers
                            ?.filter(freelancer => freelancer.projects_id?.includes(project?.id))
                            .map(freelancer => (
                                <div className="col-md-4" key={freelancer.id}>
                                    <div
                                        className="me-2 p-3 my-2 border d-flex flex-column justify-content-between align-items-center">
                                        {/*<p>IMAGE </p>*/}
                                        <p>{t("AppliedFreelancers.full_name")}: {freelancer.first_name} - {freelancer.last_name} </p>
                                        <p>{t("AppliedFreelancers.phone")}: {freelancer.phone}</p>
                                        <div className="d-flex align-items-center">
                                            <Link className="btn btn-success me-3"
                                                  to={`/freelancer_profile/${freelancer.id}`}>{t("AppliedFreelancers.open_profile")}</Link>
                                            <CreateChatBtn project={project} invited_u_id={freelancer.id}/>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </>
            }
        </>
    )
}
export default AppliedFreelancers