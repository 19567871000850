import ProjectItem from "./ProjectItem";
import {FC, useState} from "react";
import {IProject} from "../models/models";

interface ProjectsListProps {
    mode?: string,
    projects?: IProject[]
}


export const ProjectsList: FC<ProjectsListProps> = ({mode, projects}) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10);

    if (!Array.isArray(projects)) {
        return <div>- NO ITEMS -</div>;
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = projects?.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };

    return (
        <>
            {projects && currentItems?.map((project) => (
                <ProjectItem mode={mode} key={project.id} project={project}/>
            ))}

            {projects && projects.length > itemsPerPage && (
                <div className="video_nav_img_wrapper">
                    <div className="video_nav_img">
                        <ul>
                            {Array.from({length: Math.ceil(projects.length / itemsPerPage)}, (_, index) => (
                                <li className="btn btn-info" key={index} onClick={() => paginate(index + 1)}
                                >{index + 1}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </>
    )
}
