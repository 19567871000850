import PageTitle from "../components/PageTitle";
import LoginForm from "../components/LoginForm";
import {useNavigate} from "react-router-dom";
import {useCallback, useEffect} from "react";
import {useAuth} from "../hooks/useAuth";
import {useTranslation} from "react-i18next";

const LoginPage = () => {

    const {t} = useTranslation()

    const user = useAuth();

    const navigate = useNavigate();

    const handleNavigation = useCallback(() => {
        navigate("/");
    }, [navigate]);

    useEffect(() => {
        if (user && user.id) {
            handleNavigation();
        }
    }, [user, handleNavigation]);

    const titleText: string = t("LoginPage.login")
    return (
        <>
            <PageTitle title={titleText}/>
            <LoginForm/>
        </>
    )
}

export default LoginPage