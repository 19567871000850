import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';
import {IChat, IMessage, INewChat} from "../../models/models";

export const chatApi = createApi({
    reducerPath: 'chatApi',
    baseQuery: customFetchBase,
    tagTypes: ['Chat'],
    endpoints: (builder) => ({
        getAllChats: builder.query<IChat[], number>({
            query(id) {
                return {
                    url: `chats/get_chats/${id}`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
            providesTags: ()=>['Chat']
        }),
        getAllMessages: builder.query<IMessage[], number>({
            query(id) {
                return {
                    url: `chats/get_messages/${id}`,
                    credentials: 'include',
                    method: 'GET',
                };
            },
        }),
        sendMessage: builder.mutation<any, IMessage>({
            query(data) {
                return {
                    url: `chats/send_message`,
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
        }),
        createChat: builder.mutation<any, INewChat>({
            query(data) {
                return {
                    url: `chats/create_chat`,
                    credentials: 'include',
                    method: 'POST',
                    body: data,
                };
            },
        }),
    }),
});

export const {useGetAllChatsQuery, useGetAllMessagesQuery, useSendMessageMutation, useCreateChatMutation} = chatApi;
