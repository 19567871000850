import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

interface LikedProjectResp {
    project_ids?: number[]
    error?: string
    success?: string
}

export const likedProjectApi = createApi({
    reducerPath: 'likedProjectApi',
    baseQuery: customFetchBase,
    tagTypes: ['LikedProject'],
    endpoints: (builder) => ({
        getLikedProjects: builder.query<LikedProjectResp, void>({
            query() {
                return{
                    url: `liked_projects/get_liked_projects`,
                    method: 'GET',
                    credentials: 'include',
                };
            },
            providesTags: () => ["LikedProject"]
        }),
        addLikedProject: builder.mutation<LikedProjectResp, { project_id: number }>({
            query(data) {
                return{
                    url: `liked_projects/add_liked_project`,
                    method: 'POST',
                    body: data,
                    credentials: 'include',
                };
            },
            invalidatesTags: () => ["LikedProject"]
        }),
        removeLikedProject: builder.mutation<LikedProjectResp, number>({
            query(project_id) {
                return{
                    url: `liked_projects/remove_liked_project/${project_id}`,
                    method: 'DELETE',
                    credentials: 'include',
                };
            },
            invalidatesTags: () => ["LikedProject"]
        }),

    }),
});

export const {useAddLikedProjectMutation, useGetLikedProjectsQuery, useRemoveLikedProjectMutation} = likedProjectApi;
