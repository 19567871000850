import Profile from "../components/Profile";
import {useNavigate} from "react-router-dom";
import PageTitle from "../components/PageTitle";
import React, {useEffect, useState} from "react";
import {useAuth} from "../hooks/useAuth";
import {useTranslation} from "react-i18next";

const ProfilePage = () => {
    const {t} = useTranslation()
    const user = useAuth()
    const navigate = useNavigate()


    useEffect(() => {
        if (user && user?.type === "admin"){
            navigate("/dashboard")
        }else if(user?.error){
            navigate("/")
        }
    }, [user]);

    const title = t("ProfilePage.title")

    return (
        <>
            <PageTitle title={title}/>
            {user?.type === "freelancer" &&
                <Profile user={user}/>
            }
        </>
    )
}

export default ProfilePage