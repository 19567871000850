// import ChooseJobType from "../components/ChooseJobType";
import FindJob from "../components/FindJob";
// import TrandingJobs from "../components/TrandingJobs";
import MainBlockHomePage from "../components/MainBlockHomePage";
import React from "react";


const HomePage = () => {

    return (
        <>
            <div className="jp_top_header_img_wrapper">
                <div className="jp_slide_img_overlay"></div>
                <FindJob/>
            </div>
            {/*<ChooseJobType/>*/}
            {/*<TrandingJobs/>*/}
            <MainBlockHomePage/>
        </>
    )
}

export default HomePage