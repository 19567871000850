import {createApi} from '@reduxjs/toolkit/query/react';
import customFetchBase from './customFetchBase';

interface AppliedProjectsResponce {
    project_ids?: number[]
    error?: string
    success?: string
}

export const applyProjectApi = createApi({
    reducerPath: 'applyProjectApi',
    baseQuery: customFetchBase,
    tagTypes: ['ApplyProject'],
    endpoints: (builder) => ({
        getAppliedProjects: builder.query<AppliedProjectsResponce, void>({
            query() {
                return {
                    url: `applied_projects/get_projects_to_freelancer`,
                    method: 'GET',
                    credentials: 'include',
                };
            },
            providesTags: () => ["ApplyProject"]
        }),
        addApplyProject: builder.mutation<AppliedProjectsResponce, { project_id: number }>({
            query(data) {
                return {
                    url: `applied_projects/add_project_to_freelancer`,
                    method: 'POST',
                    body: data,
                    credentials: 'include',
                };
            },
            invalidatesTags: () => ["ApplyProject"]
        }),
        removeApplyProject: builder.mutation<AppliedProjectsResponce, number>({
            query(project_id) {
                return {
                    url: `applied_projects/remove_project_to_freelancer/${project_id}`,
                    method: 'DELETE',
                    credentials: 'include',
                };
            },
            invalidatesTags: () => ["ApplyProject"]
        }),

    }),
});

export const {useAddApplyProjectMutation, useGetAppliedProjectsQuery, useRemoveApplyProjectMutation} = applyProjectApi;
