import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {IFreelancer} from "../../models/models";
import {RootState} from "../store";

interface IUserState {
  user: IFreelancer | null;
}

const initialState: IUserState = {
  user: null,
};

export const userSlice = createSlice({
  initialState,
  name: 'userSlice',
  reducers: {
    logout: () => initialState,
    setUser: (state, action: PayloadAction<IFreelancer>) => {
      state.user = action.payload;
    },
  },
});

export default userSlice.reducer;

export const { logout, setUser } = userSlice.actions;

export const selectCurrentUser = (state: RootState) => state.userState.user

